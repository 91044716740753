<template>
  <v-container class="pa-" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <v-dialog v-model="loading" persistent>
            <v-card class="pa-4">
              <v-progress-circular indeterminate color="red"></v-progress-circular>
              <span class="ml-4" style="font-size: 14px">Please wait...</span>
            </v-card>
          </v-dialog>
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <v-card color="transparent" style="border-radius: 10px 10px 10px 10px;">
            <v-img class="rounded-t-lg " height="auto" :src="zoneDetails.hasOwnProperty('image') &&
              zoneDetails.image != '' &&
              zoneDetails.image != null
              ? zoneDetails.image
              : zoneImgURL
              " :lazy-src="zoneDetails.hasOwnProperty('image') &&
                zoneDetails.image != '' &&
                zoneDetails.image != null
                ? zoneDetails.image
                : zoneImgURL
                " alt="Parking.com garage">
              <v-row no-gutters>
                <v-col> <v-overlay absolute></v-overlay>
                </v-col>
                <v-col cols="12">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                  <v-col cols="12">
                    <p class="booking_header pt-3" style="color: white;position: relative;top: 0;margin-top: 45px;">
                      Welcome to
                      {{
                        zoneDetails.hasOwnProperty("zoneName")
                          ? zoneDetails.zoneName
                          : ""
                      }}
                    </p>
                  </v-col>


                </v-col>
                <v-col cols="12" v-if="isPreAuthEnabled">
                  <pre-auth-sheet style="position: absolute;bottom:0;z-index: 100;padding: 2px;"></pre-auth-sheet>

                </v-col>
              </v-row>
            </v-img>
          </v-card>



          <v-card-text class="pa-0">
            <v-form ref="detailsForm">
              <v-container fluid pa-0>
                <v-row no-gutters class="pt-2 information pa-0">
                  <v-col cols="2" class="align-self-center">
                    <v-img src="../assets/list_divider_step.imageset/list_divider_step.png" width="45px"
                      v-if="infoFlag()==false">
                      <div class="row text-center align-self-center pl-5">
                        <div class="col-12 text-center">
                          <span style="
                                                                          font-size: large;
                                                                          font-weight: bold;
                                                                          color: white;
                                                                        ">1</span>
                        </div>
                      </div>
                    </v-img>
                    <v-img src="../assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                      width="45px" v-if="infoFlag() == true">
                      <div class="row text-center pl-5">
                        <div class="col-12 text-center">
                          <span style="
                                                                          font-size: larger;
                                                                          font-weight: bold;
                                                                          color: white;
                                                                        ">✓</span>
                        </div>
                      </div>
                    </v-img>
                  </v-col>
                  <v-col cols="10" class="mt-3">
                    <p style="color: #f2555c; font-weight: bolder">
                      Your Information
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="!isPQREndReservation">
                  <v-col cols="12" class="px-4 mt-2 text-center color-black" style="font-size: 12px">
                    <p class="mb-0" v-if="zoneDetails.lpr_status == '1'">
                      Enter your license plate and phone number for hassle-free
                      parking
                    </p>
                    <p class="mb-0" v-else>
                      Enter your phone number for hassle-free parking
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="px-4 text-center color-black" style="font-size: 12px">

                    <!-- The below container is used for the normal PQR flow -->
                    <v-container fluid pa-0 v-if="pqrVerificationFlag == 'PLATE'">
                      <v-row no-gutters class="py-2">
                        <v-col cols="1" class="text-right align-self-center">
                          <v-icon class="material-icons-outlined">send_to_mobile</v-icon>
                        </v-col>
                        <v-col cols="11" class="px-2" v-if="!edtPhoneInput">
                          <VuePhoneNumberInput v-model="contactObj.phoneNumber" size="md" @update="onContactInput"
                            show-code-on-list :default-country-code="contactOptions.defaultCountry"
                            :disabled="contactDisabled" :color="contactOptions.color"
                            :valid-color="contactOptions.validColor" :error-color="contactOptions.errorColor"
                            :border-radius="contactOptions.contactBorderRadius" :error="!isContactValid" required
                            :translations="contactOptions.translations" />
                        </v-col>
                        <v-col cols="11" class="px-2" v-if="edtPhoneInput">
                          <v-text-field v-model="contactObj.phoneNumber" disabled></v-text-field>
                        </v-col>
                        <v-col cols="1"> </v-col>
                        <v-col cols="11" class="px-4" v-if="!isPQREndReservation">
                          <span style="font-size: 10px; line-height: 1.5">Phone number is mandatory for generating the
                            receipt</span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="py-4">
                        <v-col cols="1" class="text-right align-self-center">
                          <v-icon class="material-icons-outlined">pin</v-icon>
                        </v-col>
                        <v-col cols="11" class="px-2">
                          <v-text-field class="pt-0 plate-region-label" v-model="licencePlate" hide-details="auto"
                            maxlength="8" :rules="[rules.alphaNumeric]" @keyup="uppercase" label="License Plate *"
                            clearable></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="pt-1" v-if="isPQREndReservation">
                        <v-spacer></v-spacer>
                        <v-col cols="11" class="px-2 py-0 pb-3">
                          <p class="text-left mb-0" style="font-size: 10px; line-height: 1.5">
                            Enter your full plate number to avoid citation. Max of
                            8 characters. Do not include spaces or special
                            characters.
                          </p>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="pa-2">
                        <v-col cols="1"></v-col>
                        <v-col cols="5" class="pr-1">
                          <v-select :disabled="!checkValidValue(licencePlate)" class="pt-0 plate-region-label" dense
                            v-model="plateRegion.country" :items="countries" item-text="name" item-value="abbreviation"
                            :menu-props="{ offsetY: true }" hide-details label="Country *" @change="onCountryChange()">
                            <template v-slot:selection="data">
                              <v-row no-gutters>
                                <v-col cols="2" v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'abbreviation')">
                                  <vue-country-flag
                                :country="data.item.abbreviation"
                                class="flag"
                                size='small'
                              />
                                </v-col>
                                <v-col cols="10" class="country-name" v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'name')">
                                  <span class="px-2">{{
                                data.item.name
                              }}</span>
                                </v-col>
                              </v-row>
                            </template>

                            <template v-slot:item="data">
                              <template
                                v-if="Object.hasOwnProperty.call(data, 'item') && Object.hasOwnProperty.call(data.item, 'abbreviation') && Object.hasOwnProperty.call(data.item, 'name')"
                              >
                                <div class="d-flex align-center">
                                  <vue-country-flag
                                    :country="data.item.abbreviation"
                                    class="flag"
                                  />
                                  <span class="country-name px-2 pt-2">{{
                                    data.item.name
                                  }}</span>
                                </div>
                              </template>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                          <v-select :disabled="!checkValidValue(licencePlate)" class="pt-0 plate-region-label" dense
                            v-model="plateRegion.state" :items="countryStates" item-text="name"
                            @change="() => infoFlag()" item-value="abbreviation" hide-details="auto"
                            :rules="[rules.isRequired]"
                            :label="plateRegion.country!='' ? plateRegion.country=='US' ? 'State *' : 'Province *' : 'State *'"></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                    <!-- The below container is for the credit card verification flow  -->
                    <v-container fluid pa-0 v-if="pqrVerificationFlag == 'CREDITCARD'">
                      <v-row no-gutters class="py-2">
                        <v-col cols="1" class="text-right align-self-center">
                          <v-icon class="material-icons-outlined">send_to_mobile</v-icon>
                        </v-col>
                        <v-col cols="11" class="px-2" v-if="!edtPhoneInput">
                          <VuePhoneNumberInput v-model="contactObj.phoneNumber" size="md" @update="onContactInput"
                            show-code-on-list :default-country-code="contactOptions.defaultCountry"
                            :disabled="contactDisabled" :color="contactOptions.color"
                            :valid-color="contactOptions.validColor" :error-color="contactOptions.errorColor"
                            :border-radius="contactOptions.contactBorderRadius" :error="!isContactValid" required
                            :translations="contactOptions.translations" />
                        </v-col>
                        <v-col cols="11" class="px-2" v-if="edtPhoneInput">
                          <v-text-field v-model="contactObj.phoneNumber" disabled hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="1"> </v-col>
                        <v-col cols="11" class="px-4" v-if="!isPQREndReservation">
                          <span style="font-size: 10px; line-height: 1.5">Phone number is mandatory for generating the
                            receipt</span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="py-3">
                        <v-col cols="1" class="text-right align-self-center">
                          <v-icon class="material-icons-outlined">credit_card
                          </v-icon>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field class="px-2" v-mask="'####'" v-model="ccLastFourDigits" dense
                            label="Last Four Digits *" hide-details="auto"
                            :rules="[ccLastFourDigits.length != 4 ? 'Please enter a valid digits' : true]" type="number"
                            maxlength="4"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field v-mask="'##/####'" dense v-model="expiryDateInput" maxlength="7"
                            :rules="[expiryDateInput.length != 7 ? 'Please enter a valid digits' : true]"
                            label="Exp Date *" placeholder="MM/YYYY" hide-details="auto"></v-text-field>
                        </v-col>
                      </v-row>

                    </v-container>
                    <v-row no-gutters class="mt-2">
                      <v-col cols="12" class="mt-4 pb-2 text-center"
                        v-if="isPQREndReservation && !isPqrExitTimeInfoVisible">
                        <v-btn text color="primary" style="
                            font-size: 13px;
                            font-weight: bolder;
                            text-decoration: underline;
                          " @click="displayExitTimeInfoStep()">
                          <font-awesome-icon class="mr-1" icon="fa-solid fa-hotel"
                            style="font-size: 1.5rem; color: #1e3050" />
                          I am an overnight hotel guest</v-btn>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-if="!isPqrExitTimeInfoVisible">
                      <v-col cols="12" class="text-left pt-5">
                        <v-btn text color="#f2555c"
                          style="text-transform: none !important;font-size: 14px; font-weight: 500;" tile depressed
                          @click="changeVerificationMode()">
                          Verify by {{ pqrVerificationFlag == 'CREDITCARD' ? 'license plate' : 'credit card' }} instead
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="isPQREndReservation && isPqrExitTimeInfoVisible">
                  <v-col cols="12">
                    <v-row no-gutters class="pt-2 information pa-0">
                      <v-col cols="2" class="align-self-center">
                        <v-img src="../assets/list_divider_step.imageset/list_divider_step.png" width="45px"
                          v-if="!timeFlag">
                          <div class="row text-center align-self-center pl-5">
                            <div class="col-12 text-center">
                              <span style="
                                                                              font-size: large;
                                                                              font-weight: bold;
                                                                              color: white;
                                                                            ">2</span>
                            </div>
                          </div>
                        </v-img>
                        <v-img src="../assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                          width="45px" v-if="timeFlag">
                          <div class="row text-center pl-5">
                            <div class="col-12 text-center">
                              <span style="
                                        font-size: larger;
                                        font-weight: bold;
                                        color: white;
                                      ">✓</span>
                            </div>
                          </div>
                        </v-img>
                      </v-col>
                      <v-col cols="10" class="mt-3">
                        <p style="color: #f2555c; font-weight: bolder">
                          Set Your Exit Date <span
                            v-if="isPQREndReservation && Object.hasOwnProperty.call(zoneDetails, 'timePickerConfiguration') && zoneDetails.timePickerConfiguration == '1'">and
                            Time</span>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-2 ">
                      <v-spacer></v-spacer>
                      <v-col cols="11" class="px-2 py-0">
                        <p class="text-left ma-0" v-if="isPQREndReservation">
                          If you're a hotel guest, this is your checkout date.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" class="px-4 text-center color-black" style="font-size: 12px">
                        <v-row no-gutters class="py-2">
                          <v-col cols="1" class="text-right align-self-center">
                            <v-icon class="material-icons-outlined">today</v-icon>
                          </v-col>
                          <v-col cols="11" class="px-2">
                            <v-text-field v-model="computedDateFormatted" @click="() => { openDatePicker = true }"
                              hide-details="auto" readonly></v-text-field>
                            <v-dialog v-model="openDatePicker" max-width="450px">
                              <v-card style="background-color: #F2555C;color: white;"><v-card-title>
                                  <v-row no-gutters>
                                    <v-col class="text-left" cols="12">
                                      <span style="font-size:15px;color: #fabbbe;">{{ time !== null && time !== "" ?
                                        formattedYear : "" }}</span>
                                    </v-col>
                                    <v-col>
                                      <span style="font-size:30px"> {{ time !== null && time !== "" ? formattedDateTime
                                        :
                                        ""
                                        }}</span>
                                    </v-col>
                                  </v-row>
                                </v-card-title></v-card>
                              <Calendar inline class="text-left" value :stepMinute="15" hourFormat="12"
                                :minDate="minTimeDate" @date-select="onTimePickerChange" :maxDate="maxTimeDate"
                                v-model="time" />
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="pt-2 pb-4"
                          v-if="Object.hasOwnProperty.call(zoneDetails, 'timePickerConfiguration') && zoneDetails.timePickerConfiguration == '1'">
                          <v-col cols="1" class="text-right align-self-center">
                            <v-icon class="material-icons-outlined">schedule</v-icon>
                          </v-col>
                          <v-col cols="11">
                            <v-row no-gutters class="pb-0">
                              <v-col class="pb-0 px-2">
                                <v-text-field v-model="formattedTime" @click="() => {
                                  openTimePicker = true;
                                }" readonly></v-text-field>
                                <v-dialog v-model="openTimePicker" max-width="300px"
                                  @click:outside="() => { setDefaultDate(); onTimePickerChange() }">
                                  <v-card style="background-color: #F2555C;color: white;"><v-card-title>What time will
                                      you
                                      exit?</v-card-title></v-card>
                                  <Calendar inline time-only show-time class="text-left " value :stepMinute="15"
                                    hourFormat="12" :minDate="minTimeDate" :maxDate="maxTimeDate" v-model="time" />
                                </v-dialog>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row class="text-left mt-0 px-2 py-0 pb-3">
                          <v-col cols="1"></v-col>
                          <v-col class="pt-0">
                            <p style="
                              color: #1976d2;
                              font-weight: bold;
                              line-height: 16px;
                            " class="ma-0">
                              HOURS OF OPERATION
                            </p>
                            <p class="ma-0">
                              Today :
                              {{
                                getTodaysHoursOfOperation
                                  ? "Open 24 / 7"
                                  : ""
                              }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-2 information pa-0" v-if="estimate != null && Object.keys(estimate).length != 0
                    ">
                      <v-col cols="2" class="align-self-center">
                        <v-img src="../assets/list_divider_step.imageset/list_divider_step.png" width="45px"
                          v-if="!paymentFlag">
                          <div class="row text-center align-self-center pl-5">
                            <div class="col-12 text-center">
                              <span style="
                                                                              font-size: large;
                                                                              font-weight: bold;
                                                                              color: white;
                                                                            ">3</span>
                            </div>
                          </div>
                        </v-img>
                        <v-img src="../assets/list_divider_step_completed.imageset/list_divider_step_completed.png"
                          width="45px" v-if="paymentFlag">
                          <div class="row text-center pl-5">
                            <div class="col-12 text-center">
                              <span style="
                                                                              font-size: larger;
                                                                              font-weight: bold;
                                                                              color: white;
                                                                            ">✓</span>
                            </div>
                          </div>
                        </v-img>
                      </v-col>
                      <v-col cols="10" class="mt-3">
                        <p style="color: #f2555c; font-weight: bolder">
                          Your Parking Fees
                        </p>
                      </v-col>
                    </v-row>

                    <Estimate :singleQuotes="singleQuotes" :doubleQuotes="doubleQuotes" :estimate="estimate"
                      :beforeEstimate="beforeEstimate" :formattedEntryDisplayTime="formattedEntryDisplayTime">
                    </Estimate>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="pt-0 mt-4 pb-10">
            <v-container class="pa-0 text-center" v-if="isPQREndReservation && isPqrExitTimeInfoVisible">
              <v-row no-gutters>
                <v-col cols="12" class="px-2">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text" :disabled="disableSubmitBtn"
                    :loading="addPaymentBtnLoading" @click="addPayment">Add Payment</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="px-2 mt-4">
                  <v-btn rounded elevation="20" width="50%" class="white--text" color="primary"
                    v-if="this.casinoDetails.userDetail.bid" @click="reloadBooking">Cancel</v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="pa-0" fluid v-else>
              <v-row no-gutters>
                <v-col cols="12" class="px-2">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                    :loading="addLicencePlateBtnLoading" :disabled="disableAddLicencePlateBtn"
                    @click="createSession()">{{
                      reEnterPlate ? "Confirm" : "Continue" }}</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="mt-5 text-center color-black px-2" style="font-size: 12px">
                  <p class="mb-0" v-show="reEnterPlate">
                    Please provide correct license plate and phone number.
                    Without one, you could be subject to a parking violation and
                    issued a citation.
                  </p>
                  <p class="mb-0">
                    A valid credit card is required to park. Without one, you
                    could be subject to a parking violation and issued a
                    citation.
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="alertDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ alertMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="alertDialog = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmCreateSessionDialog" max-width="fit-content">
      <v-card class="pa-0">
        <v-card-title class="justify-center">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5" style="font-size: 15px">
          <span v-html="errMsg"></span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="setReenterPlateFlag">OK</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
// import Vue from "vue";
import VueCountryFlag from "vue-country-flag";
import Estimate from '../components/Estimate.vue'
import PreAuthSheet from "@/uiComponents/PreAuthSheet.vue";
import { EventBus } from "@/lib/EventBus";
import { mapGetters } from "vuex";
import { addMinutes, addDays, addHours } from "date-fns";
import { format, isValid } from "date-fns";
import "primevue/resources/themes/saga-blue/theme.css"       //theme
import "primevue/resources/primevue.min.css"                 //core css
import "primeicons/primeicons.css"                           //icons
import { dateToTimeZoneDate } from "@/utils/formatDateTime";
// import axios from "axios";
import Calendar from '../uiComponents/Calendar/Calendar.vue'
import APIHelper from "../apiHelper";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
// import moment from "moment";
// Vue.use(moment);
import rules from "@/utils/rules";
export default {
  data: () => ({
    expiryDateInput: "",
    ccLastFourDigits: "",
    hotelBookingType: '',
    date: "",
    time: "",
    openTimePicker: false,
    openDatePicker: false,
    minTimeDate: null,
    maxTimeDate: null,
    // isPQREndReservation: true,
    currentDate: format(new Date(), 'yyyy-MM-dd'),
    edtEntryTime: null,
    edtPhoneInput: false,
    contactDisabled: false,
    selectedPlate: "",
    reEnterPlate: false,
    edtBid: null,
    confirmCreateSessionDialog: false,
    addLicencePlateBtnLoading: false,
    errDialog: false,
    errMsg: "",
    alertDialog: false,
    alertMsg: "",
    stopTime: "",
    exitTimeSelected: {},
    timePickerHrRange: null,
    timeExceeded: false,
    timePickerMinRange: null,
    disableAddPaymentBtn: false,
    maxDateTime: null,
    getRateCall: false,
    getRateText: "",
    Timemenu: false,
    startDate: "",
    estimate: null,
    beforeEstimate: null,
    Datemenu: false,
    exitDate: null,
    dateFormatted: null,
    rules: rules,
    isContactValid: false,
    licencePlate: "",
    contactObj: {},
    loading: false,
    contactOptions: {
      color: "#0288D1",
      validColor: "#0288D1",
      contactBorderRadius: 0,
      errorColor: "#FF5252",
      defaultCountry: "US",
      translations: {
        phoneNumberLabel: "Contact No",
      },
    },
    addPaymentBtnLoading: false,
  }),
  components: {
    VuePhoneNumberInput, Calendar, Estimate, PreAuthSheet, VueCountryFlag
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      let searchParams = window.location.search.split("=");
      let searchParamKey = searchParams.length > 0 ? searchParams[0] : "";
      let searchParamValue = searchParams.length > 0 ? searchParams[1] : "";
      switch (searchParamKey) {
        case "?zcode":
          await vm.getZoneDetails(searchParamKey, searchParamValue);
          if (vm.isPQREndReservation) {
            vm.setDefaultDate();
          }
          break;
        case "?gateid":
          //await self.getZoneDetails(searchParamKey, searchParamValue);
          break;
      }
      if (vm.isPQREndReservation) {
        vm.hotelBookingType = 'hotelGuest'
        vm.contactObj =
          vm.casinoDetails != null &&
            Object.hasOwnProperty.call(vm.casinoDetails, "userDetail") &&
            Object.hasOwnProperty.call(vm.casinoDetails?.userDetail, "contact") &&
            vm.casinoDetails?.userDetail?.contact != undefined &&
            vm.casinoDetails?.userDetail?.contact != null
            ? Object.assign(vm.contactObj, {
              countryCode:
                vm.casinoDetails?.userDetail?.contact.slice(0, -10) == "+91"
                  ? "IN"
                  : vm.casinoDetails?.userDetail?.contact.slice(0, -10) ==
                    "+1"
                    ? "US"
                    : "",
              phoneNumber: vm.casinoDetails?.userDetail?.contact,
              formattedNumber: "+" + vm.casinoDetails.userDetail.contact,
            })
            : {};
        vm.licencePlate = vm.casinoDetails?.userDetail.plate
          ? vm.casinoDetails?.userDetail.plate
          : "";
        vm.plateRegion = vm.casinoDetails?.userDetail?.plateRegion?.country
          ? Object.assign(vm.plateRegion, { country: vm.casinoDetails?.userDetail?.plateRegion?.country })
          : vm.plateRegion;
        vm.onCountryChange();
        vm.plateRegion = vm.casinoDetails?.userDetail?.plateRegion?.country && vm.casinoDetails?.userDetail?.plateRegion?.state
          ? Object.assign(vm.plateRegion, { state: vm.casinoDetails?.userDetail?.plateRegion?.state })
          : vm.plateRegion;
        if (window.location.href.includes('bid')) {
          vm.$store.commit(
            "SET_DISPLAYPQR_EXITTIME_INFO",
            true
          );
          const urlObject = new URL(window.location.href);
          // Get the hash part and remove the leading `#`
          const hash = urlObject.hash.substring(1);
          // Create a URLSearchParams object from the hash
          const params = new URLSearchParams(hash.split('?')[1]);
          let hrefParams = window.location.href.split('createsession');
          let phoneParam = params.get("phone").trim() || "";
          let bidParam = params.get("bid").trim() || "";
          let plateParam = params.get("plate").trim() || "";
          let entrytimeparam = params.get("entrytime") || "";
          let countryParam = params.get("country").trim() || "";
          let stateParam = params.get("state").trim() || "";
          vm.edtEntryTime = entrytimeparam != null && entrytimeparam !== "" && entrytimeparam != undefined ? format(new Date(vm.formatExitDateTime(entrytimeparam, 'date')), "yyyy-MM-dd'T'HH:mm:ss") : format(dateToTimeZoneDate(new Date(), vm.zoneDetails.timezone), "yyyy-MM-dd'T'HH:mm:ss")
          vm.licencePlate = plateParam;
          vm.edtBid = bidParam;
          let guestContact = phoneParam ? {
            countryCode:
              phoneParam.slice(0, -10) == "91"
                ? "IN"
                : phoneParam.slice(0, -10) == "1"
                  ? "US"
                  : "",
            phoneNumber: phoneParam,
            formattedNumber: "+" + phoneParam,
          } : vm.contactObj;
          if (phoneParam !== "" && phoneParam !== null && phoneParam != undefined) {
            vm.edtPhoneInput = true;
            vm.contactObj = Object.assign({}, guestContact);
            vm.contactDisabled = true;
            vm.contactObj.isValid = true;
          }
          vm.plateRegion = Object.assign({}, { country: countryParam });
          vm.onCountryChange();
          vm.plateRegion = stateParam
            ? Object.assign(vm.plateRegion, { state: stateParam })
            : vm.plateRegion;
          let commitData = {
            plate: vm.licencePlate,
            plateRegion: {
              country: vm.plateRegion?.country,
              state: vm.plateRegion?.state,
            },
            contact: vm.contactObj?.formattedNumber,
            bid: vm.edtBid,
            entrytime: vm.edtEntryTime,
            edtParams: hrefParams[1]
          }
          vm.$store.commit("SET_CASINO_USERDETAILS", commitData);
          vm.$store.commit("SET_TEMPLATE_FLAGS", 'atlantic-flag');

        }
      }
      else {
        vm.hotelBookingType = '',
          vm.edtPhoneInput = false;
      }
    });
  },
  watch: {
    time(newVal, oldVal) {
      if (oldVal != null && newVal != null && oldVal != newVal && oldVal != "" && newVal != "" && (isValid(newVal))) {
        this.round(newVal);
      }
      // this.setTimePicker(newVal);
    },
  },
  computed: {
    isPreAuthEnabled() {
      return Object.hasOwnProperty.call(this.zoneDetails, 'preAuth') && Object.hasOwnProperty.call(this.zoneDetails?.preAuth, 'enabled') && this.zoneDetails?.preAuth?.enabled == '1'
    },
    formattedDateTime() {
      return this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'iii, LLL dd') : ""
    },
    formattedYear() {
      return this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'yyyy') : ""
    },
    formattedTime() {
      return this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'hh:mm aa') : ""
    },
    ...mapGetters({
      casinoDetails: "getterCasinoDetails", // Used to get the details of a EDT or casino type of transactions
      zoneDetails: "getterZoneDetails", // Global getter used to get the zone details 
      templateFlags: "getterTemplateFlags", // Used to get the template flag to check whether its a casino txn
      isPQREndReservation: "getterZoneIsPQREndReservation", // Flag used to get if the PQR end reservation is turned on or off
      pqrVerificationFlag: "getterPQRVerifyUsing", // This flag is used to get the verification mode of a PQR transaction either by plate or card
      isPqrExitTimeInfoVisible: "getterDisplayPqrExitTimeInfo" // TO check the overnight hotel guest transaction
    }),
    singleQuotes() {
      return Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails.timePickerConfiguration == "0" ? (format(this.time, 'yyyy-MM-dd') == this.currentDate || (this.beforeEstimate !== null && this.beforeEstimate !== undefined && this.beforeEstimate !== "" && this.estimate !== null && this.estimate !== undefined && this.estimate !== "" && this.beforeEstimate.grandTotal == this.estimate.grandTotal)) : true
    },
    doubleQuotes() {
      return Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails.timePickerConfiguration == "0" ? (format(this.time, 'yyyy-MM-dd') != this.currentDate && (this.beforeEstimate !== null && this.beforeEstimate !== undefined && this.beforeEstimate !== "" && this.estimate !== null && this.estimate !== undefined && this.estimate !== "" && this.beforeEstimate.grandTotal != this.estimate.grandTotal)) : false
    },
    formattedEntryDisplayTime() {
      return this.casinoDetails.userDetail.entrytime !== null && this.casinoDetails.userDetail.entrytime !== undefined && this.casinoDetails.userDetail.entrytime !== "" ? format((new Date(this.casinoDetails.userDetail.entrytime)), 'hh:mm a') : format(dateToTimeZoneDate(new Date(), this.zoneDetails.timezone), 'hh:mm a');
    },
  
    timeFlag() {
      return this.time == null ||
        this.disableAddPaymentBtn ||
        this.estimate === null ||
        this.estimate === "" || (Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails.timePickerConfiguration == "1" && format(this.time, 'yyyy-MM-dd') != format(this.minTimeDate, 'yyyy-MM-dd') && (this.beforeEstimate === null || this.beforeEstimate == "" || this.beforeEstimate == undefined)) ||
        this.time === "" || this.time == undefined
        ? false
        : true;
    },
    paymentFlag() {
      return this.estimate === null ||
        this.estimate === "" ||
        this.estimate.hasOwnProperty.call("grandTotal") ||
        this.estimate.hasOwnProperty.call("quotedRegularPrice") ||
        this.estimate.hasOwnProperty.call("totalFee") ||
        this.estimate.hasOwnProperty.call("totalTax") || (this.zoneDetails?.timePickerConfiguration == '1' && this.beforeEstimate === null && this.beforeEstimate === "" && this.beforeEstimate === null && this.beforeEstimate.hasOwnProperty.call("quotedRegularPrice") && this.beforeEstimate.hasOwnProperty.call("totalFee") && this.beforeEstimate.hasOwnProperty.call("totalTax"))
        ? false
        : true;
    },
    computedDateFormatted() {
      return this.time != null && this.time != "" && this.time != undefined ? format(this.time, 'MM/dd/yyyy') : ""
    },
    disableSubmitBtn() {
      let disabled =
        this.contactObj == null ||
        !this.contactObj.isValid ||
        this.contactObj.phoneNumber == "" ||
        this.contactObj.phoneNumber == null ||
        this.licencePlate === "" ||
        this.licencePlate === null ||
        this.plateRegion?.country === "" ||
        this.plateRegion?.country === null ||
        this.plateRegion?.state === "" ||
        this.plateRegion?.state === null ||
        this.disableAddPaymentBtn ||
        this.estimate === null ||
        this.estimate === ""
      this.exitDate === "";
      let selectedMins = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'mm') : new Date();
      let selectedHours = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'hh') : new Date();
      let selectedAMPM = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'aaa') : new Date();
      disabled =
        disabled ||
        selectedMins == null ||
        selectedMins === "" ||
        selectedMins == undefined ||
        selectedHours == null ||
        selectedHours === "" ||
        selectedHours == undefined || selectedAMPM == null ||
        selectedAMPM === "" ||
        selectedAMPM == undefined || this.time === null || this.time === "" || this.time === undefined
      disabled =
        this.licencePlate != "" && this.licencePlate != null
          ? disabled || /^[a-z0-9]+$/i.test(this.licencePlate) === false
          : disabled;
      return disabled;
    },
    disableAddLicencePlateBtn() {
      let disabled;
      if (this.pqrVerificationFlag == "CREDITCARD") {
        disabled = this.isContactValid == false || this.expiryDateInput == undefined || this.expiryDateInput == null || this.expiryDateInput == "" || this.ccLastFourDigits == undefined || this.ccLastFourDigits == null || this.ccLastFourDigits == "" || this.ccLastFourDigits.length != 4 || this.expiryDateInput.length != 7 ? true : false;
      }
      else {
        if (this.zoneDetails.lpr_status == "0") {
          disabled = this.isContactValid == false ? true : false;
        } else {
          disabled = this.isContactValid == false ||
            this.licencePlate == "" ||
            this.licencePlate == null ||
            /^[a-z0-9]+$/i.test(this.licencePlate) === false ||
            this.plateRegion?.country === "" ||
            this.plateRegion?.country === null ||
            this.plateRegion?.state === "" ||
            this.plateRegion?.state === null
            ? true
            : false;
        }
      }
      return disabled

    },
  },
  mounted() {
    EventBus.$on('SET_TIME', () => {
      this.onTimePickerChange();
    })
    this.setDefaultDate();
  },
  methods: {
    infoFlag() {
      return this.contactObj == null ||
        !this.contactObj.isValid ||
        this.contactObj.phoneNumber == "" ||
        this.contactObj.phoneNumber == null ||
        this.licencePlate == "" ||
        this.licencePlate == null ||
        this.plateRegion?.state == "" ||
        this.plateRegion?.state == null ||
        this.plateRegion?.country == "" ||
        this.plateRegion?.country == null 
        
        ? false
        : true;
    },
    formatExpiryDate(val) {
      console.log(val)
    },
    displayExitTimeInfoStep() {
      this.$store.commit(
        "SET_DISPLAYPQR_EXITTIME_INFO",
        true
      );
      this.$store.commit('SET_PQR_VERIFY_FLAG', 'PLATE')

    },

    //This method is used to toggle the PQR verification mode to  (verify by plate) or (verify by plate )
    changeVerificationMode() {
      switch (this.pqrVerificationFlag) {
        case 'PLATE': {
          this.$store.commit('SET_PQR_VERIFY_FLAG', 'CREDITCARD');
          break;
        }
        case 'CREDITCARD': {
          this.$store.commit('SET_PQR_VERIFY_FLAG', 'PLATE')
          break;
        }
      }

    },
    round(time) {
      let exitdate = time !== null && time !== '' ? format(time, 'yyyy/MM/dd') : new Date();

      let selectedMins = time !== null && time !== '' ? format(time, 'mm') : new Date();
      let selectedHours = time !== null && time !== '' ? format(time, 'hh') : new Date();
      let selectedAMPM = time !== null && time !== '' ? format(time, 'aaa') : new Date();
      if (exitdate != null && selectedMins != '15' && selectedMins != '30' && selectedMins != '45' && selectedMins != '00' && selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "") {
        let intervalMilliseconds = 900000
        let datetime = new Date(exitdate + " " + selectedHours + ":" + selectedMins + " " + selectedAMPM)
        let interval = datetime.getTime() % intervalMilliseconds;
        let delta = interval === 0 ? 0 : datetime.getTime() - interval;
        delta += intervalMilliseconds;
        // this.exitTimeSelected = Object.assign(this.exitTimeSelected, { hh: format(new Date(delta), 'hh'), mm: format(new Date(delta), 'mm'), a: format(new Date(delta), 'a'), })
        return interval !== 0 ? new Date(delta) : datetime;
      }
    },
    dateChange() {
      this.dateTimeChange();
      Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails?.timePickerConfiguration == "1" ? this.beforeDateTimeChange() : "";
    },
    formatExitDateTime(time, val) {
      if (time !== null && time !== undefined && time != '') {
        let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
        let month = utcDate.split(",")[1].split(" ")[2]; // Jan
        let date = utcDate.split(",")[1].split(" ")[1]; // 27
        let year = utcDate.split(",")[1].split(" ")[3];
        let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
        let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
        let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
        formatHr = Number(formatHr) >= 10 ? Number(formatHr) : formatHr; //01
        let amOrpm = Number(hr) >= 12 ? "p" : "a"; //PM
        let ampm = Number(hr) >= 12 ? "PM" : "AM";
        switch (val) {
          case 'hour': {
            return formatHr + amOrpm;
          }
          case 'mins': {
            return min
          }
          case 'date': {
            return month + " " + date + ", " + year + " " + formatHr + ":" + min + " " + ampm;
          }
        }
      }
      //Jan 27, 01:59 PM
    },
    reloadBooking() {
      //url segment is app mixin computed property
      let url = window.location.origin + '/g/' + this.casinoDetails.userDetail.bid;
      window.location.replace(url);
    },
    setDefaultDate() {
      this.dateFormatted = this.zoneDetails.timezone ? format(
        dateToTimeZoneDate(addMinutes(new Date(), 15),
          this.zoneDetails.timezone
        ),
        "MM/dd/yyyy"
      )
        : format(addMinutes(new Date(), 15), "MM/dd/yyyy");
      this.time = this.zoneDetails.timezone
        ?
        dateToTimeZoneDate(
          addMinutes(new Date(), 15),
          this.zoneDetails.timezone
        )
        : addMinutes(new Date(), 15);
      let fifteenTime = this.time;
      this.time = format(this.time, 'mm') === '15' || format(this.time, 'mm') === '30' || format(this.time, 'mm') === '45' || format(this.time, 'mm') === '00' ? fifteenTime : this.round(this.time);
      let selectedMins = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'mm') : "";
      let selectedHours = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'HH') : "";
      let selectedAMPM = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'a') : "";
      this.stopTime = selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "" ? selectedHours + ":" + selectedMins + ":" + "00" : "";
      // this.dateTimeChange()
      this.setDatePicker();
      // Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails?.timePickerConfiguration == "1" && format(this.time, 'yyyy-MM-dd') != format(dateToTimeZoneDate(new Date(), this.zoneDetails.timezone), 'yyyy-MM-dd') ? this.beforeDateTimeChange() : "";
    },
    onTimePickerChange() {
      let exitdate = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'yyyy/MM/dd') : "";
      let selectedMins = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'mm') : "";
      let selectedHours = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'HH') : "";
      let selectedAMPM = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'a') : "";
      this.stopTime = selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "" ? selectedHours + ":" + selectedMins + ":" + "00" : "";
      let currenTime = this.zoneDetails.timezone
        ? format(
          dateToTimeZoneDate(new Date(),
            this.zoneDetails.timezone
          ),
          "yyyy-MM-dd HH:mm:ss"
        )
        : format(new Date(), "yyyy-MM-dd  HH:mm:ss");
      if (
        selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "" &&
        new Date(exitdate + " " + this.stopTime).getTime() >
        new Date(currenTime).getTime()
      ) {
        format(this.time, 'yyyy-MM-dd') != format(this.minTimeDate, 'yyyy-MM-dd') ? this.beforeDateTimeChange() : "";
        this.dateTimeChange();

      }
    },
    setDatePicker() {
      //set the minimum date to today date(zone time zone date)
      this.minTimeDate = this.zoneDetails.timezone ? this.round(this.zoneDetails.timezone
        ?
        dateToTimeZoneDate(addMinutes(new Date(), 15),
          this.zoneDetails.timezone
        )
        : addMinutes(new Date(), 15)) : this.zoneDetails.timezone
        ?
        dateToTimeZoneDate(addMinutes(new Date(), 15),
          this.zoneDetails.timezone
        )
        : addMinutes(new Date(), 15)
      //calculate max parkign time in seconds
      let maxPark = this.zoneDetails != null && this.zoneDetails !== undefined && this.zoneDetails != "" ? this.zoneDetails?.maxParkingTime : null;

      let addeddays = addDays(new Date(), maxPark?.days);
      let addedhours = addHours(addeddays, maxPark?.hours);
      let addedmins = addMinutes(addedhours, maxPark?.minutes);

      //get max date in seconds based on max parking time
      let maxZoneDate = addedmins
      this.maxTimeDate = this.zoneDetails.timezone
        ?
        dateToTimeZoneDate(
          new Date(maxZoneDate),
          this.zoneDetails.timezone
        )
        : new Date(maxZoneDate);
      //get max date with time and set it to maxDateTime
      this.maxDateTime = this.zoneDetails.timezone
        ? format(
          dateToTimeZoneDate(
            new Date(maxZoneDate),
            this.zoneDetails.timezone
          ),
          "yyyy-MM-dd hh:mm aaaaa"
        )
        : format(new Date(), "yyyy-MM-dd hh:mm aaaaa");
    },

    /** @methods to find the estimate rate 
     @params startDate , stopDate,zcode, type , env_overRide
     */

    async dateTimeChange() {
      if (!this.zoneDetails.zcode) {
        return
      }
      try {
        // format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");
        const urlObject = new URL(window.location.href);
          // Get the hash part and remove the leading `#`
          const hash = urlObject.hash.substring(1);
          // Create a URLSearchParams object from the hash
          const params = new URLSearchParams(hash.split('?')[1]);
        let edtEntryTime = ""
        if (window.location.href.includes('entrytime')) {
          edtEntryTime = params.get("entrytime")
        }

        this.startDate = edtEntryTime !== null && edtEntryTime !== undefined && edtEntryTime !== "" ? format(new Date(this.formatExitDateTime(edtEntryTime, 'date')), "yyyy-MM-dd'T'HH:mm:ss") : this.zoneDetails.timezone
          ? format(
            dateToTimeZoneDate(
              new Date(),
              this.zoneDetails.timezone
            ),
            "yyyy-MM-dd'T'HH:mm:ss"
          )
          : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
        // console.log(this.exitTimeSelected?.hh+":"+this.exitTimeSelected.mm+":00")
        // this.exitDate + "T23:59:00"
        let stopTime = this.stopTime;
        let exitdate = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'yyyy-MM-dd') : "";
        let exittime = Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails?.timePickerConfiguration == '1' ? 'T' + stopTime : "T23:59:00";
        let stopDate = exitdate + exittime;
        let data = {
          startAt: this.startDate,
          stopAt: stopDate,
          zcode: this.zoneDetails.zcode,
        };
        this.addPaymentBtnLoading = true;
        var getRate = await APIHelper("POST", "/api/v2/getRate", data);
        //condition to check if the date time selected is lesser than current time
        if (!getRate?.data?.status && !this.getRateCall) {
          this.disableAddPaymentBtn = true;
          this.alertDialog = true;
          this.alertMsg =
            getRate?.data?.message || "Error in getting rate details";
          this.openDatePicker = false;
          this.openTimePicker = false;
          EventBus.$emit('datetime-set');

        } else {
          this.disableAddPaymentBtn = false;
          this.openDatePicker = false;
          this.openTimePicker = false;
          EventBus.$emit('datetime-set');

        }
        this.estimate = Object.assign({}, getRate?.data?.data);
        // estimatedVal != "" &&
        // estimatedVal != null &&
        // estimatedVal != undefined
        //   ? Number(estimatedVal).toFixed(2)
        //   : estimatedVal;
        this.openDatePicker = false;
        this.openTimePicker = false;
        this.addPaymentBtnLoading = false;
      }
      catch (error) {
        this.addPaymentBtnLoading = false;
        console.log(error);
      }
    },
    async beforeDateTimeChange() {
      if (!this.zoneDetails.zcode) {
        return
      }
      try {
         // format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");
         const urlObject = new URL(window.location.href);
          // Get the hash part and remove the leading `#`
          const hash = urlObject.hash.substring(1);
          // Create a URLSearchParams object from the hash
          const params = new URLSearchParams(hash.split('?')[1]);
        let edtEntryTime = ""
        if (window.location.href.includes('entrytime')) {
          edtEntryTime = params.get("entrytime")
        }
        this.startDate = edtEntryTime !== null && edtEntryTime !== undefined && edtEntryTime !== "" ? format(new Date(this.formatExitDateTime(edtEntryTime, 'date')), "yyyy-MM-dd'T'HH:mm:ss") : this.zoneDetails.timezone
          ? format(
            dateToTimeZoneDate(
              new Date(),
              this.zoneDetails.timezone
            ),
            "yyyy-MM-dd'T'HH:mm:ss"
          )
          : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
        let stopTime = this.stopTime;
        let selectedHours = this.casinoDetails.userDetail.entrytime != null && this.casinoDetails.userDetail.entrytime !== undefined && this.casinoDetails.userDetail.entrytime != "" ? format(new Date(this.casinoDetails.userDetail.entrytime), 'HH') : format(dateToTimeZoneDate(new Date(), this.zoneDetails.timezone), 'HH');
        let selectedMins = this.casinoDetails.userDetail.entrytime != null && this.casinoDetails.userDetail.entrytime !== undefined && this.casinoDetails.userDetail.entrytime != "" ? format(new Date(this.casinoDetails.userDetail.entrytime), 'mm') : format(dateToTimeZoneDate(new Date(), this.zoneDetails.timezone), 'mm');
        let exitdate = this.time !== null && this.time !== undefined && this.time !== "" ? format(this.time, 'yyyy-MM-dd') : "";
        let exittime = Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails?.timePickerConfiguration == '0' ? 'T' + stopTime : 'T' + selectedHours + ":" + selectedMins + ":00";

        let stopDate = exitdate + exittime;
        let data = {
          startAt: this.startDate,
          stopAt: stopDate,
          zcode: this.zoneDetails.zcode,
        };
        this.addPaymentBtnLoading = true;
        var getRate = await APIHelper("POST", "/api/v2/getRate", data);
        //condition to check if the date time selected is lesser than current time
        if (!getRate?.data?.status && !this.getRateCall) {
          this.disableAddPaymentBtn = true;
          this.alertDialog = true;
          this.alertMsg =
            getRate?.data?.message || "Error in getting rate details";
          this.openDatePicker = false;
          this.openTimePicker = false;
          EventBus.$emit('datetime-set');
        } else {
          this.disableAddPaymentBtn = false;
          this.openDatePicker = false;
          this.openTimePicker = false;
          EventBus.$emit('datetime-set');
        }
        this.beforeEstimate = Object.assign({}, getRate?.data?.data);
        // estimatedVal != "" &&
        // estimatedVal != null &&
        // estimatedVal != undefined
        //   ? Number(estimatedVal).toFixed(2)
        //   : estimatedVal;
        this.addPaymentBtnLoading = false;
      }
      catch (error) {
        this.addPaymentBtnLoading = false;
        console.log(error);
      }
    },
    formatDate(date) {
      let stringdate = date.toString();
      if (!date) return null;

      const [year, month, day] = stringdate.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async addPayment() {
      let exitDate = this.time != null || this.time !== undefined || this.time != "" ? format(this.time, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd')
      let exitTime = this.time != null || this.time !== undefined || this.time != "" ? Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails.timePickerConfiguration == 1 ? format(this.time, 'hh:mm a') : "11:59 PM" : "";
      let exitDateTime = exitDate + " " + exitTime
      let entryDateTime = this.zoneDetails.timezone
        ? format(
          dateToTimeZoneDate(new Date(), this.zoneDetails.timezone),
          "yyyy-MM-dd hh:mm a"
        )
        : format(new Date(), "yyyy-MM-dd'T'HH:mm a");
      let commitData = {
        plate: this.licencePlate,
        plateRegion: {
          country: this.plateRegion?.country,
          state: this.plateRegion?.state,
        },
        contact: this.contactObj?.formattedNumber,
        entryTime: entryDateTime,
        edtParams: this.casinoDetails?.userDetail?.edtParams,
        exitTime: exitDateTime,
        estimate: this.estimate,
      };
      let details = await APIHelper("POST", "/api/v1/prepaid/openTransaction", {
        vehicleNo: this.licencePlate,
        plateRegion: {
          country: this.plateRegion?.country,
          state: this.plateRegion?.state,
        },
        contact: this.contactObj?.formattedNumber,
        exitTime: exitDateTime, zcode: this.zoneDetails?.zcode,
        mid: this.zoneDetails?.mid,
        eventType: "PaidHostPass",
        edtBookingType: this.hotelBookingType
      });

      if (details?.data?.status) {
        this.$store.commit("SET_CASINO_USERDETAILS", commitData);
        this.$router.replace({ path: "/prepaidhost/makepayment" });
      } else {
        if (details?.data?.message == "Booking Already Exists") {
          let commitData = {
            plate: this.licencePlate,
            plateRegion: {
              country: this.plateRegion?.country,
              state: this.plateRegion?.state,
            },
            contact: this.contactObj.formattedNumber,
            entryTime: entryDateTime,
            edtParams: this.casinoDetails?.userDetail?.edtParams,
            exitTime: exitDateTime,
            estimate: this.estimate,
            bid: details?.data?.data?.bid
          };
          if (details?.data?.data?.cardOnFile == 0 || details?.data?.data?.cardOnFile == 2) {
            this.$store.commit("SET_CASINO_USERDETAILS", commitData);
            this.$router.replace({ path: "/prepaidhost/makepayment" });
          }
          else if (details?.data?.data?.cardOnFile == 1) {
            // this.$store.commit("SET_CASINO_USERDETAILS", commitData);
            // this.$router.replace({ path: "/prepaidhost/odReceipt" });

            //url segment is app mixin computed property
            let url = window.location.origin + '/g/' + details?.data?.data?.bid;
            window.location.replace(url);
          }
        }
      }
    },

    uppercase() {
      if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
        this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
      }
      this.licencePlate = this.licencePlate.trim()
      this.licencePlate = this.licencePlate.toUpperCase();
    },
    /**
     * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
     */
    async getZoneDetails(searchParamKey, searchParamValue) {
      try {
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
      } catch (error) {
        console.log(error);
      }
    },
    onContactInput(contactDetails) {
      this.isContactValid = contactDetails?.isValid;
      this.contactObj = Object.assign({}, contactDetails);
    },
    setReenterPlateFlag() {
      this.licencePlate = this.selectedPlate || "";
      this.reEnterPlate = true;
      this.confirmCreateSessionDialog = false;
    },
    /**
     * @method createSession Fetch booking id on add booking and fetch the booking info.
     */
    async createSession() {
      this.addLicencePlateBtnLoading = true;
      let postObj = {
        contact: this.contactObj?.formattedNumber,
        uniqueID: this.contactObj?.nationalNumber,

        locationCode: this.zoneDetails.zcode,
        zid: this.zoneDetails.zid,
        entrytime: format(
          new Date(
            new Date().toLocaleString("en-US", {
              timeZone: this.zoneDetails.timezone,
            })
          ),
          "yyyy-MM-dd HH:mm:ss"
        ),
        source: "appclip",
        webView: "1",
        type: "2",
        state: 2,
        payment_status: 0,
        txnID_addCC: 0,
      };
      switch (this.pqrVerificationFlag) {
        case 'PLATE': {
          Object.assign(postObj, {
            vehicleNo: this.licencePlate.toUpperCase(), plateRegion: {
              country: this.plateRegion?.country,
              state: this.plateRegion?.state,
            },
          })
          break;
        }
        case "CREDITCARD": {
          Object.assign(postObj, {
            ccExpiryDate: this.expiryDateInput,
            ccLastFourDigits: this.ccLastFourDigits
          })
          break;
        }
        default: {
          Object.assign(postObj, {
            vehicleNo: this.licencePlate.toUpperCase(), plateRegion: {
              country: this.plateRegion?.country,
              state: this.plateRegion?.state,
            },
          })
          break;
        }
      }
      if (this.reEnterPlate) {
        Object.assign(postObj, { createNewSession: 1 });
      }
      try {
        var session = await APIHelper("POST", "/api/v1/addBooking", postObj);
        let bid = session?.data?.refno;
        if (this.pqrVerificationFlag == "CREDITCARD" && session.data.status == false) {
          this.alertMsg = session?.data?.message ? session?.data?.message : "";
          this.alertDialog = true
        }
        if (session.data.status === false) {
          this.errMsg = session?.data?.message ? session.data.message : "";
          if (
            session?.data?.showCreateNewSessionPopup &&
            session.data.showCreateNewSessionPopup == 1
          ) {
            this.postToLoggerAPI({
              phone: this.contactObj?.nationalNumber,
              plate: this.licencePlate.toUpperCase(),
              plateRegion: {
                country: this.plateRegion?.country,
                state: this.plateRegion?.state,
              },
              statusText: "Parker session not found in the system.",
            });
            this.confirmCreateSessionDialog = true;
            this.addLicencePlateBtnLoading = false;
            return;
          }
          this.errDialog = true;
          this.addLicencePlateBtnLoading = false;
          return;
        }
        await this.getBookingDetails(bid);
        //url segment is app mixin computed property
        let url = window.location.origin + '/g/' + bid;
        this.addLicencePlateBtnLoading = false;
        this.reEnterPlate
          ? this.postToLoggerAPI({
            phone: this.contactObj?.nationalNumber,
            refId: bid,
            icon: "login",
            plate: this.licencePlate.toUpperCase(),
            statusText:
              "Parker plate/contact number not found in the system. Parker created a new session using generic payment QR",
          })
          : this.postToLoggerAPI({
            phone: this.contactObj?.nationalNumber,
            refId: bid,
            icon: "login",
            plate: this.licencePlate.toUpperCase(),
            statusText:
              "Parker session found. Parker opened his/her session using generic payment QR",
          });

        window.location.replace(url);
      } catch (error) {
        this.addLicencePlateBtnLoading = false;
        console.log(error);
      }
    },
    /**
     * @method getBookingDetails fetch the booking details and commit SET_BOOKING_DETAILS mutation.
     */
    async getBookingDetails(bookingId) {
      try {
        var bookingDetails = await APIHelper(
          "GET",
          "/api/v1/booking/config/" + bookingId
        );
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
      } catch (error) {
        console.log(error);
      }
    },
    closeConfirmCreateSessionDialog() {
      this.confirmCreateSessionDialog = false;
      this.addLicencePlateBtnLoading = false;
    },
  },
};
</script>
<style lang="less">
.information {
  background-color: #edf1f4;
}

.booking_header {
  position: absolute;
  bottom: 30px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.country-selector.md.has-value .country-selector__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
}

.input-tel.md .input-tel__input {
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  margin-left: 2px;
}

.input-tel.is-focused.has-error .input-tel__input {
  box-shadow: none !important;
}

.input-tel.is-focused.is-valid .input-tel__input {
  box-shadow: none !important;
}

.time-picker-input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  font-size: 16px;
  font-family: "Work Sans";
}

.time-picker-input :focus-visible {
  outline-color: #1976d2;
}

.vue__time-picker input.display-time {
  padding: 0px !important;
  border-bottom: 1px solid #767676;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}

.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #f2555c !important;
}

[data-key="am"] {
  border: 0.2px solid red;
  border-radius: 2px;
  margin-left: 2px;
}

[data-key="pm"] {
  border: 0.2px solid red;
  margin-top: 0.5em;
  margin-left: 2px;
}

.hours .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.apms .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  margin-left: 1px;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

.hours::-webkit-scrollbar {
  display: none;
}

.minutes::-webkit-scrollbar {
  display: none;
}

.minutes .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px;
}

hr {
  border: 1px solid #ffbc1f;
}

input[type=number] {
  -moz-appearance: textfield;
}

.booking_header {
  font-size: 18px;
  z-index: 100;
  font-weight: bolder;
}

// .vue__time-picker input.display-time{
//   border-bottom:1px solid #767676;
//     border-top:0px;
//     border-right:0px;
//     border-left:0px;
// }</style>
